// JobTitleSelect.tsx
import React, { useEffect, useState } from 'react';
import { Select } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInternalJobTitles,
  getExternalJobTitles,
} from '../../../apps/main/rootReducer';
import { fetchInternalJobTitles } from '../../settings/actions/internalJobTitles';
import { fetchExternalJobTitles } from '../../settings/actions/externalJobTitles';

interface JobTitleSelectProps {
  onChange?: (value: string) => void;
  value?: string;
}

const JobTitleSelect: React.FC<JobTitleSelectProps> = ({ onChange, value }) => {
  const dispatch = useDispatch();
  const [jobTitleOptions, setJobTitleOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const internalJobTitles = useSelector(getInternalJobTitles);
  const externalJobTitles = useSelector(getExternalJobTitles);

  useEffect(() => {
    dispatch(fetchInternalJobTitles());
    dispatch(fetchExternalJobTitles());
  }, [dispatch]);

  useEffect(() => {
    const combinedTitles = [
      ...internalJobTitles?.map((title) => ({
        label: title.name,
        value: title.name,
      })),
      ...externalJobTitles?.map((title) => ({
        label: title.name,
        value: title.name,
      })),
    ];
    setJobTitleOptions(combinedTitles);
  }, [internalJobTitles, externalJobTitles]);

  const handleSearch = (value: string) => {
    if (value && !jobTitleOptions.some((option) => option.value === value)) {
      setJobTitleOptions((prevOptions) => [
        ...prevOptions,
        { label: value, value },
      ]);
    }
  };
  return (
    <Select
      showSearch
      options={jobTitleOptions}
      onChange={onChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      onSearch={handleSearch}
      value={value ?? ''}
    />
  );
};

export default JobTitleSelect;
